import * as React from 'react'
import styled, { css } from 'styled-components'
import { Button } from './Button'
import { Link } from 'gatsby'

interface ICTAProps {
  inverted: boolean
}

const CTA = styled<ICTAProps, any>('div')`
  position: relative;
  padding-top: 6rem;
  padding-bottom: 6rem;

  ${(props) =>
    props.inverted &&
    css`
      background-color: ${props.color || `${props.theme.colors.purple}`};
      h3 {
        color: #fff;
      }
      button {
        color: ${props.theme.colors.dark};
      }
      span {
        color: rgba(255, 255, 255, 0.8) !important;
      }
    `};

  @media (max-width: 939px) {
    text-align: center;
  }
`
const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;

  @media (min-width: 600px) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  @media (min-width: 940px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 3rem;
    padding-right: 3rem;
  }
`

export const CTAContainer = Container
export const StyledCTA = CTA

const Title = styled.div`
  margin-bottom: 1.5rem;
  max-width: 620px;
  > * {
    line-height: 1.5;
  }
  .c-cta--secondary .c-cta__title > * {
    color: #fff;
  }
  @media (min-width: 600px) {
    margin-bottom: 2rem;
  }
  @media (min-width: 940px) {
    margin: 0;
  }
  @media (max-width: 939px) {
    margin-left: auto;
    margin-right: auto;
  }
  span {
    color: ${(props) => props.theme.colors.secondary};
    font-weight: 400;
  }
`

interface Props {
  title: string
  subtitle: string
  button: string
  inverted?: boolean
  color?: string
  to?: string
}

export default ({ to, title, subtitle, button, inverted, ...rest }: Props) => (
  <CTA inverted={inverted} {...rest}>
    <Container>
      <Title>
        <h3>
          {title}
          <br />
          <span>{subtitle}</span>
        </h3>
      </Title>
      <Link to={to || '/contact#form'}>
        <Button inverted={!inverted}>{button}</Button>
      </Link>
    </Container>
  </CTA>
)
